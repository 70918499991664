import React, { Fragment } from "react"
import Layout, { SimpleLayout } from "../components/layout"
import indexStyles from "./index.module.css"
import Hero from "../components/hero"
import { Link } from "gatsby"
import Circle from "../components/circle"
import { FaArrowRight } from "react-icons/fa"
import ImageCostEffective from "../components/image-cost-effective"
import ImageTrainee from "../components/image-trainee"

import imageCircleImmersive from "../images/sectors-graphic.gif"
import oculusisvpartnerSVG from "../images/oculus_isv_partner.svg"

import Benefits from "../components/benefits"

import Products from "../components/products"
import Clients from "../components/clients"
import Features from "../components/features"
import Connected from "../components/connected"
import { FooterWrapper } from "../components/footer"

export default ({ data }) => (
  <Fragment>
    <FooterWrapper>
      <Layout>
        <Hero
          heroleft
          hero="Get better results with immersive training"
          herosub="VR training in First Aid, Life support and more. For Healthcare and Enterprise"
        />
        <div className="desktop">
          <div style={{ marginTop: `40px`, marginBottom: `100px` }}>
            <Link className={indexStyles.button} to="https://www.oculus.com/experiences/quest/5320252051348244">
            AVAILABLE ON OCULUS QUEST
            </Link>
            {/* <Link className={indexStyles.button} to="/enterprise">
              VR FOR ENTERPRISE
            </Link> */}
          </div>
        </div>
        <div className="mobile">
          <div style={{ marginTop: `80px`, marginBottom: `80px` }}>
            <Link className={indexStyles.button} to="https://www.oculus.com/experiences/quest/5320252051348244">
            AVAILABLE ON OCULUS QUEST
            </Link>
          </div>
        </div>
        {/* <ImageCards /> */}
        <img src={oculusisvpartnerSVG} alt="" style={{ marginTop: `0px`, marginBottom: `60px` }}/>
      </Layout>

      <SimpleLayout style={{ background: "white", padding: "80px 0" }}>
        <CircleImmersion />
      </SimpleLayout>

      <Benefits />
      <SimpleLayout
        style={{
          backgroundImage:
            "linear-gradient(0.25turn,rgba(99, 69, 148, 1),rgba(97, 169, 254, 1))",
        }}
      >
        <Products />
      </SimpleLayout>

      <Clients>
        <h1>Clients</h1>
      </Clients>

      <SimpleLayout
        style={{
          backgroundImage:
            "linear-gradient(0.25turn,rgba(99, 69, 148, 1),rgba(97, 169, 254, 1))",
        }}
      >
        <Features />
      </SimpleLayout>

      <SimpleLayout
        style={{
          background: "white",
        }}
      >
        <Connected />
      </SimpleLayout>
    </FooterWrapper>
  </Fragment>
)

const CircleImmersion = props => (
  <div className={indexStyles.circleImmersion}>
    <div className="desktop">
      <Circle />
    </div>
    <div className="mobile">
      <img width="100%" src={imageCircleImmersive} alt="circle" />
    </div>
    <div className={indexStyles.circleImmersionTextColumn}>
      <h1>Bringing immersive training to organisations globally</h1>
      <p>
        While focusing on Healthcare and Enterprise, our software is suitable
        for various sectors, including Education, Manufacturing and many others.
        Working with organisations from across the globe.
      </p>
      <Link to="/contact">
        LET'S MEET YOUR TEAM
        <FaArrowRight
          style={{
            fontSize: "1.2em",
            verticalAlign: "bottom",
            marginLeft: "10px",
          }}
        />
      </Link>
    </div>
  </div>
)

const ImageCards = props => (
  <div className={indexStyles.imageCardContainer}>
    <div className={indexStyles.imageCard}>
      <div className={indexStyles.imageCardImage}>
        <ImageCostEffective />
      </div>
      <div style={{ width: "180px", marginLeft: "20px" }}>
        <h2>Cost-effective & frequent training</h2>
        <p>An affordable method for frequent employee training</p>
      </div>
    </div>
    <div className={indexStyles.imageCard}>
      <div className={indexStyles.imageCardImage}>
        <ImageTrainee />
      </div>
      <div style={{ width: "250px", marginLeft: "20px", marginBottom: "80px" }}>
        <h2>Trainee engagement & objective scoring</h2>
        <p>
          Engaging trainee whilst providing live data of performance and
          feedback
        </p>
      </div>
    </div>
  </div>
)
