import React, { Fragment } from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import styles from "./circle.module.css"

import ambulanceSVG from "../images/ambulance.svg"
import ambulanceWhiteSVG from "../images/ambulance-white.svg"

import hospitalSVG from "../images/hospital.svg"
import hospitalWhiteSVG from "../images/hospital-white.svg"

import cogsSVG from "../images/cogs.svg"
import cogsWhiteSVG from "../images/cogs-white.svg"

import schoolSVG from "../images/school.svg"
import schoolWhiteSVG from "../images/school-white.svg"

import universitySVG from "../images/university.svg"
import universityWhiteSVG from "../images/university-white.svg"

import heartSVG from "../images/heart.svg"
import heartWhiteSVG from "../images/heart-white.svg"

import moreSVG from "../images/more.svg"
import moreWhiteSVG from "../images/more-white.svg"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        mainImage: file(relativePath: { eq: "circle.png" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <div className={styles.container}>
          <div className={[styles.circle, styles.circleOuter].join(" ")}>
            <div className={[styles.circle, styles.circleInner].join(" ")}>
              <Img fixed={data.mainImage.childImageSharp.fixed} />
              <div className={[styles.ambulance, styles.button].join(" ")}>
                <h1>Emergency services</h1>
                <img
                  className={styles.showonnormal}
                  src={ambulanceSVG}
                  alt=""
                />
                <img
                  className={styles.showonhover}
                  src={ambulanceWhiteSVG}
                  alt=""
                />
              </div>
              <div className={[styles.cogs, styles.button].join(" ")}>
                <h1>Industry & Enterprise</h1>
                <img className={styles.showonnormal} src={cogsSVG} alt="" />
                <img className={styles.showonhover} src={cogsWhiteSVG} alt="" />
              </div>
              <div className={[styles.hospital, styles.button].join(" ")}>
                <h1>Healthcare</h1>
                <img className={styles.showonnormal} src={hospitalSVG} alt="" />
                <img
                  className={styles.showonhover}
                  src={hospitalWhiteSVG}
                  alt=""
                />
              </div>
              <div className={[styles.school, styles.button].join(" ")}>
                <h1>Education</h1>
                <img className={styles.showonnormal} src={schoolSVG} alt="" />
                <img
                  className={styles.showonhover}
                  src={schoolWhiteSVG}
                  alt=""
                />
              </div>
              <div className={[styles.university, styles.button].join(" ")}>
                <h1>Higher education</h1>
                <img
                  className={styles.showonnormal}
                  src={universitySVG}
                  alt=""
                />
                <img
                  className={styles.showonhover}
                  src={universityWhiteSVG}
                  alt=""
                />
              </div>
              <div className={[styles.heart, styles.button].join(" ")}>
                <h1>Training organisations</h1>
                <img className={styles.showonnormal} src={heartSVG} alt="" />
                <img
                  className={styles.showonhover}
                  src={heartWhiteSVG}
                  alt=""
                />
              </div>
              <div className={[styles.more, styles.button].join(" ")}>
                <h1>... and more</h1>
                <img className={styles.showonnormal} src={moreSVG} alt="" />
                <img className={styles.showonhover} src={moreWhiteSVG} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )}
  />
)
