import React, { Fragment } from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import styles from "./benefits.module.css"
import stylesLayout from "./layout.module.css"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        costtimeImage: file(relativePath: { eq: "cost-time.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engagementImage: file(relativePath: { eq: "engagement.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        learningImage: file(relativePath: { eq: "learning.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <div className={styles.benefits}>
          <div className={styles.container + " " + stylesLayout.layout}>
            <div className={styles.item}>
              <div className={styles.circle}>
                <Img fixed={data.costtimeImage.childImageSharp.fixed} />
              </div>
              <h1>Cost & time reduction</h1>
              <h2>
                VR technology provides the methods to train in a cheaper and
                time-efficient way
              </h2>
            </div>
            <div className={styles.item}>
              <div className={styles.circle}>
                <Img fixed={data.engagementImage.childImageSharp.fixed} />
              </div>
              <h1>Increased engagement</h1>
              <h2>
                Immersing trainees in a real-life scenario encourages learner
                participation
              </h2>
            </div>
            <div className={styles.item}>
              <div className={styles.circle}>
                <Img fixed={data.learningImage.childImageSharp.fixed} />
              </div>
              <h1>Improved learning outcomes</h1>
              <h2>
                More knowledge retention is achieved by VR-based learning than traditional teaching
                methods
              </h2>
            </div>
          </div>
        </div>
      </Fragment>
    )}
  />
)
