import React from "react"
import styles from "./features.module.css"

import imageEngaging from "../images/features/engaging.svg"
import imageUser from "../images/features/user.svg"
import imageSoftware from "../images/features/software.svg"

import imageDashboard from "../images/features/dashboard.svg"
import imageHandson from "../images/features/handson.svg"
import imageBespoke from "../images/features/bespoke.svg"

const Features = props => (
  <div className={styles.features}>
    <h1>Features</h1>
    <div className={styles.container}>
      <div className={styles.item}>
        <img src={imageEngaging} alt="" />
        <h2>Engaging scenario</h2>
        <h2>simulation</h2>
      </div>
      <div className={styles.item}>
        <img src={imageUser} alt="" />
        <h2>User-centered</h2>
        <h2>approach</h2>
      </div>
      <div className={styles.item}>
        <img src={imageSoftware} alt="" />
        <h2>Software</h2>
        <h2>support & updates</h2>
      </div>

      <div className={styles.item + " " + styles.itemHideMobile}>
        <hr />
      </div>
      <div className={styles.item + " " + styles.itemHideMobile}>
        <hr />
      </div>
      <div className={styles.item + " " + styles.itemHideMobile}>
        <hr />
      </div>

      <div className={styles.item}>
        <img src={imageDashboard} alt="" />
        <h2>User performance</h2>
        <h2>dashboard</h2>
      </div>
      <div className={styles.item}>
        <img src={imageHandson} alt="" />
        <h2>'Hands-on' experience</h2>
        <h2>with tactile feedback</h2>
      </div>
      <div className={styles.item}>
        <img src={imageBespoke} alt="" />
        <h2>Bespoke scenario</h2>
        <h2>customisation</h2>
      </div>

      {/* <div className={styles.item}>
        <hr />
      </div>
      <div className={styles.item}>
        <hr />
      </div>
      <div className={styles.item + " " + styles.itemHideMobile}>
        <hr />
      </div> */}
    </div>
  </div>
)

export default Features
