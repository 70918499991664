import React, { Fragment } from "react"
import Card from "../components/card"
import { FiPlusCircle } from "react-icons/fi"
import styles from "./products.module.css"

import imageHealthcare from "../images/cards/card-healthcare.png"
import imageEnterprise from "../images/cards/card-enterprise.png"

const ListItem = props => (
  <li className={styles.listitem + " " + props.extrastyle}>
    <FiPlusCircle
      style={{
        fontSize: "22px",
        verticalAlign: "middle",
        marginRight: "10px",
      }}
    />
    <span style={{ fontSize: "15px", verticalAlign: "middle" }}>
      {props.children}
    </span>
  </li>
)

const Products = props => (
  <Fragment>
    <div className={styles.products}>
      <Card
        buttonTitle="SEE PRODUCTS"
        cardImage={imageHealthcare}
        to="/healthcare"
        height="540px"
      >
        <h1>Healthcare</h1>
        <h2>VR Medical training</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul style={{ textAlign: "left" }}>
            <ListItem>Basic Life Support</ListItem>
            <ListItem>Advanced Life Support</ListItem>
            <ListItem>Paediatric Life Support</ListItem>
            <ListItem>OB Hemorrhage</ListItem>
            <ListItem>Auscultation trainer</ListItem>
          </ul>
        </div>
      </Card>
      <Card
        buttonTitle="SEE PRODUCTS"
        cardImage={imageEnterprise}
        to="/enterprise"
        height="540px"
      >
        <h1>Enterprise</h1>
        <h2>VR Health & Safety training</h2>
        <h3>For all employees</h3>
        <p>
          These courses are created for organisations that want to add immersive learning methods to their mandatory training, in the areas of First Aid and CPR.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul style={{ textAlign: "left", float: "left", marginTop: "20px" }}>
            <ListItem extrastyle={styles.floatleft}>CPR training</ListItem>
            <ListItem extrastyle={styles.floatleft}>First Aid training</ListItem>
          </ul>
        </div>
      </Card>
    </div>
  </Fragment>
)

export default Products
